.custom-card {
  border: 1.5px solid white;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
  color: white;
  padding: 10px 20px;
  font-weight: 600;
  width: 100%;
}

.login-card {
  border: 1.5px solid red;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
  color: #F64923;
  padding: 12px 8px 12px 8px;
  font-weight: 600;
  width: 100%;
}

.images-container {
  position: relative;
  padding: 50px 0;
  width: 100%;

  img {
    max-width: 80%;
    margin: 0 auto;
    display: block;
  }
}
