@import "../../../_metronic/assets/sass/components/variables.custom";

.nav-item .nav-link {
    color: $gray-100-dark !important;
    background-color: $gray-900-dark !important;
    font-size: 12pt;
    border-bottom: 2px solid #e3e3e3 !important;
    // box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.nav-item .nav-link.active {
    color: $primary-light !important;
    background-color: $primary !important;
    box-shadow: none !important;
}