.custom-alert {
    display: inline-block;
    width: 385px;
    padding: 0 30px;
  
    
  }

  .w-custom {
    width: 50% !important;
}
  
.custom-padding {
    padding-right:50px;
  
    
  }

  .custom-alert-user {
    display: inline-block;
    width: 740px;
    padding: 0 30px;
  
    
  }