/* validation pass labels */
.custom-container {
  width: 100%;
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
}

.custom-label {
  align-self: stretch;
  color: #1f1f1f;
  font-size: 14px;
  font-family: Hind;
  font-weight: 600;
  letter-spacing: 0.5px;
  word-wrap: break-word;
}

.custom-items {
  align-self: stretch;
  height: 64px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.custom-item {
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.custom-icon-container {
  width: 16px;
  height: 16px;
  position: relative;
}

.custom-icon-inner {
  width: 13.33px;
  height: 13.33px;
  left: 1.33px;
  top: 1.33px;
  position: absolute;
  border: 1.5px #99a1b7 solid;
}

.custom-icon-inner-small {
  width: 8.67px;
  height: 6.67px;
  left: 6px;
  top: 2.67px;
  position: absolute;
  border: 1.5px #99a1b7 solid;
}

.custom-green-text {
  color: #4bc520;
  font-size: 14px;
  font-family: Hind;
  font-weight: 400;
  line-height: 16px;
  word-wrap: break-word;
}

.custom-gray-text {
  color: #99a1b7;
  font-size: 14px;
  font-family: Hind;
  font-weight: 400;
  line-height: 16px;
  word-wrap: break-word;
}

/* end */

.container-button > button {
  color: white;
  font-size: 20px;
  font-family: Hind;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

.container-button-light > button {
  color: #989898;
  font-size: 14.3px;
  font-family: Inter;
  font-weight: 500;
  line-height: 21.45px;
  word-wrap: break-word;
}

.custom-link-container > .link-primary {
  color: #0079c0;
  font-size: 16px;
  font-family: Hind;
  font-weight: 500;
  word-wrap: break-word;
}

.custom-button > .btn-success {
  color: white;
  font-size: 20px;
  font-family: Hind;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  background-color: #00a65a;
}

.input-style > .input,
input {
  color: #99a1c5;
  font-size: 16px;
  font-family: Hind;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

.custom-button-change-pass > .button {
  color: white;
  font-size: 20px;
  font-family: Hind;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

.custom-verification-title > h1,
.h1 {
  color: #071437;
  font-size: 32px;
  font-family: Hind;
  font-weight: 700;
  word-wrap: break-word;
}

.custom-verification-text {
  color: black;
  font-size: 14px;
  font-family: Hind;
  font-weight: 400;
  word-wrap: break-word;
}

.custom-verification-label {
  color: #071437;
  font-size: 14px;
  font-family: Hind;
  font-weight: 600;
  line-height: 20.96px;
  word-wrap: break-word;
}

.custom-login-title > h1,
.h1 {
  color: #071437;
  font-size: 32px;
  font-family: Hind;
  font-weight: 700;
  text-transform: uppercase;
  word-wrap: break-word;
}

.custom-menu-label {
  color: black;
  font-size: 14px;
  font-family: Hind;
  font-weight: 500 !important;
  word-wrap: break-word;
}

.custom-menu-link {
  color: #ff0000;
}

.custom-sidebar-menu {
  color: #f9f9f9;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500 !important;
  line-height: 21px;
  word-wrap: break-word;
}

.custom-text-forgot {
  color: #2b2b2b;
  font-size: 32px;
  font-family: Hind, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  word-wrap: break-word;
}

.custom-text-2-forgot {
  color: #2b2b2b;
  font-size: 14px;
  font-family: Hind, sans-serif;
  font-weight: 400;
  word-wrap: break-word;
}

input::placeholder {
  color: #99a1c5;
}

.get-code-label {
  font-size: 14px;
  font-family: Hind;
  font-weight: 400;
  line-height: 22.43px;
  word-wrap: break-word;
  color: #5b5b5b;
}

.change-pass-h1 > h1 {
  color: #2b2b2b;
  font-size: 32px;
  font-family: Hind;
  font-weight: 700;
  text-transform: uppercase;
  word-wrap: break-word;
}

.base-label-change {
  font-size: 14px;
  font-family: Hind;
  word-wrap: break-word;
}

.change-pass-label {
  color: #2b2b2b;
  font-weight: 400;
}

.change-pass-label-sing {
  color: #5494fa;
  font-weight: 600;
}

.base-style {
  font-size: 14px;
  font-family: Hind;
  line-height: 24px;
  word-wrap: break-word;
}

.change-validation-label {
  color: #9fa8b2;
  font-weight: 400;
}

link:hover:not(.disabled):not(.active):not(.here) {
  transition: color 0.2s ease;
  color: var(--bs-primary);
}

.menu-link-general {
  color: black !important;
  transition: color 0.3s ease;
  font-weight: 500;
}

.menu-link-general:hover {
  transition: color 0.2s ease;
  color: #3699ff !important;
}

.menu-link-new {
  color: #e43c5a !important;
  transition: color 0.3s ease;
  font-weight: 500;
}

.menu-link-new:hover {
  transition: color 0.2s ease;
  color: #3699ff !important;
}

.alert-button {
  color: white;
  font-size: 14.3px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  line-height: 21.45px;
  word-wrap: break-word;
  background-color: #00a65a !important;
}

.btn.btn-success-alert {
  color: white !important;
}

.alert-button {
  color: white;
  font-size: 14.3px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  line-height: 21.45px;
  word-wrap: break-word;
  background-color: #00a65a !important;
}

.alert-title {
  color: black;
  font-size: 12.4px;
  font-family: Inter;
  font-weight: 500;
  line-height: 18.52px;
  word-wrap: break-word;
}
/*tabla components*/
.right-table-container {
  width: 100%;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: rgba(0, 166, 90, 0.25);
  border-radius: 8px;
  border: 1px solid #00a65a;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.btn.btn-outline-dashed {
  border: none;
  border-width: 0;
}

.custom-padding-log {
  padding-right: 73px;
}

.custom-padding-user {
  padding-right: 420px;
}

.custom-button-log > .btn-outline-danger {
  color: #e43c5a !important;
  font-size: 12px;
  font-family: Hind;
  font-weight: 500;
  word-wrap: break-word;
}

.custom-button-log-bt2 > .btn-success {
  color: white;
  font-size: 12px;
  font-family: Hind;
  font-weight: 500;
  word-wrap: break-word;
  background-color: #00a65a;
}

.custom-button-log-bt-delete > .btn-success {
  color: #e43c5a !important;
  font-size: 12px;
  font-family: Hind;
  font-weight: 500;
  word-wrap: break-word;
}

.custom-button-log-bt3 > .btn-light {
  color: #505050;
  font-size: 12px;
  font-family: Hind;
  font-weight: 500;
  word-wrap: break-word;
}

.custom-border-bottom {
  border-bottom: 0 !important;
}
.custom-border-top {
  border-top: 0 !important;
}

.submenu-custom {
  color: #78829d;
  font-size: 12.4px;
  font-family: Inter;
  font-weight: 500;
  line-height: 18.52px;
  word-wrap: break-word;
}

.color-log-details {
  color: #a0a0a0;
  font-size: 14px;
  font-family: "Hind", sans-serif;
  font-weight: 400 !important;
  word-wrap: break-word;
}

.custom-button-log-details > .badge-success {
  color: white;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  background-color: #00a65a;
}

.custom-badge > .badge {
  --bs-badge-padding-x: 0.7rem;
  --bs-badge-padding-y: 0.62rem;
}
.custom-table-logs-title {
  color: black;
  font-size: 20px;
  font-family: Hind, sans-serif;
  font-weight: 600;
  word-wrap: break-word;
}

.custom-form-control-plaintext {
  /* color: #99A1C5 !important; */
  /* color: #4b5675 !important; */
  color: #99a1c5 !important;
  font-size: 16px;
  font-family: Hind;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}
.custom-form-control-plaintext2 {
  /* color: #99A1C5 !important; */
  color: #4b5675 !important;
  /* color: #99A1C5 !important; */
  font-size: 16px;
  font-family: Hind;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

/* .custom-form-control-plaintext:focus, .custom-form-control-plaintext:active {
  transition: color 0.2s ease;
  color: #4b5675 !important;
} */

/* .custom-form-control-plaintext:focus {
  transition: color 0.2s ease;
  color: #4b5675 !important;
} */

.custom-name-log {
  color: black;
  font-size: 20px;
  font-family: Hind;
  font-weight: 600;
  word-wrap: break-word;
}

.custom-status-log {
  color: white;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500 !important;
  word-wrap: break-word;
}

.custom-filter-button {
  color: black;
  font-size: 16px;
  font-family: Hind;
  font-weight: 400;
  word-wrap: break-word;
}

.custom-button-table > .btn-light-success {
  color: #00a65a !important;
  font-size: 16px;
  font-family: Hind;
  font-weight: 400;
  word-wrap: break-word;
  border: 0.5px solid #50cd89 !important;
}

.custom-button-table > .btn-light-success:hover,
.btn-light-success:active {
  color: white !important;
  font-size: 16px;
  font-family: Hind;
  font-weight: 400;
  word-wrap: break-word;
  border: 0.5px solid #50cd89 !important;
}

.highlight-borders {
  border: 1.5px solid #50cd89 !important;
}

.custom-header-title {
  color: #99a1b7 !important;
  font-size: 12.4px !important;
  font-family: Inter;
  font-weight: 600 !important;
  text-transform: uppercase;
  line-height: 18.52px !important;
  word-wrap: break-word;
}

.custom-table-data {
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20.96px;
  word-wrap: break-word;
}

.custom-button-table-action {
  color: #78829d !important;
  font-size: 12.4px;
  font-family: Inter;
  font-weight: 500;
  line-height: 18.52px;
  word-wrap: break-word;
}

.custom-search {
  color: #99a1b7;
  font-size: 14.3px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.custom-button-export {
  color: #00a65a !important;
  font-size: 16px !important;
  font-family: Hind;
  font-weight: 400 !important;
  word-wrap: break-word;
}

.custom-button-add > .btn-success {
  color: white !important;
  font-size: 16px !important;
  font-family: Hind;
  font-weight: 400 !important;
  word-wrap: break-word;
  background-color: #00a65a !important;
}

.title-bread {
  color: #071437 !important;
  font-size: 17.6px;
  font-family: Inter !important;
  font-weight: 600 !important;
  line-height: 21px;
  word-wrap: break-word;
}
.custom-sub-title-bread {
  color: #99a1b7 !important;
  font-size: 12.4px !important;
  font-family: Inter !important;
  font-weight: 500 !important;
  line-height: 18.52px;
  word-wrap: break-word;
}

.custom-submenu {
  color: #8a8a8a !important;
  font-size: 12px !important;
  font-family: Hind !important;
  font-weight: 600 !important;
  word-wrap: break-word !important;
}

.custom-submenu-1 {
  color: black;
  font-size: 14px;
  font-family: Hind;
  font-weight: 500;
  word-wrap: break-word;
}

.custom-title-add {
  color: #2b2b2b;
  font-size: 32px;
  font-family: Hind;
  font-weight: 700;
  word-wrap: break-word;
}

.custom-title2-add {
  color: #2b2b2b;
  font-size: 14px;
  font-family: Hind;
  font-weight: 400;
  word-wrap: break-word;
}

.custom-title-log {
  color: black;
  font-size: 20px;
  font-family: Hind;
  font-weight: 600;
  word-wrap: break-word;
}

.custom-label-form {
  color: #7e7e7e;
  font-size: 14px;
  font-family: Hind;
  font-weight: 600;
  word-wrap: break-word;
}
