.custom-code-input {
  input[type=number] {
   font-family: monospace;
   border-radius: 6.18px;
   border: 1px #DBDFE9 solid;
   box-shadow: rgba(200, 216, 234, 0.1) 0px 0px 10px 0px;
   margin: 12px;
   padding-left: 12px;
   padding-right: 1px;
   width: 65px;
   height: 60px;
   font-size: 32px;
   box-sizing: border-box;
   color: black;
   text-align: center;
   background-color: white;
   justify-content: start;
   outline: none;

   -moz-appearance: textfield;

   &::-webkit-outer-spin-button,
   &::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
   }
 }
}

.custom-code-input input[type=number]:focus {
 border-radius: 6.18px;
   border: 1px #b0b0c7  solid;
   background-color: #ececf8;
}

.custom-code-input input[type=number]:hover {
   border: 1px #B5B5C3 solid;
   background-color: #f8f8f8;
}

.custom-code-input input[type=number]:hover:focus {
 border: 1px #B5B5C3 solid; 
 background-color: #ececf8; 
}


