.custom-select {
  display: inline-block;
  width: 385px;
  padding: 0 30px;
}

.custom-select-smaller {
  width: auto;
}

.w-custom {
  width: 160% !important;
}

.custom-padding {
  padding-right: 50px;
}
.custom-select-add-user {
  display: inline-block;
  width: 740px;
  padding: 0 30px;
}

.bg-custom-green > .form-control-plaintext {
  background-color: transparent !important;
}
