.custom-input {
    display: inline-block;
    width: 385px;
    padding: 0 30px;
  
    
  }

  .w-custom-input {
    width: 160% !important;
}
  
.custom-padding-input {
    padding-right:50px;
  
    
  }